
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































.steps {
  display: flex;
  flex-flow: column-reverse wrap;

  @include mq($from: l) {
    flex-direction: row;
  }
}

.steps__content {
  @include mq($from: l) {
    width: col(6, 14);
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}

.steps__visual-container {
  width: col(9, 14);
  margin-bottom: 5rem;

  @include mq($from: l) {
    width: col(6, 14);
    margin-bottom: 0;
    margin-left: col(2, 14);
  }
}

.steps__title {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 50px, xxl: 90px));

  display: flex;
  flex-direction: column;
}

.steps__title__second-part {
  color: $c-gray-dark;
}

.step {
  &:not(:last-of-type) {
    margin-bottom: 6rem;
  }

  .wysiwyg {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}

.step__title {
  margin-bottom: 1rem;

  &::before {
    content: attr(data-index);
    color: $c-main-green;
  }
}

.steps-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
