
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































































































































































































































































































.bubbles-outer {
  margin-top: 0;
}

.bubbles__title {
  margin-bottom: 0.5em;
}

.bubbles {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;

  @include mq(l) {
    height: 100vh;
    max-height: 66rem;
    background-color: $c-main-beige;
  }
}

.bubble__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1680px;
  height: 1680px;
  transform: translate(-50%, -50%);
}

.bubbles__item__outer {
  position: relative;
  width: 100%;
  height: 100%;

  @include mq($until: l) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.bubbles__item {
  position: absolute;
  text-align: center;
  text-decoration: none;

  &:hover {
    .bubbles__item__icon-outer {
      opacity: 1;
    }

    .bubbles__item__icon {
      transform: translate(-50%, -50%);

      @include mq($until: l) {
        transform: none;
      }
    }

    .bubbles__item__picture-outer {
      border-color: $c-main-green;
    }

    .picture-inner {
      transform: scale(1.1);
    }
  }

  @include mq($until: l) {
    position: relative;
    display: flex;
    gap: 1.6rem;
    align-items: center;
    padding: 1rem;
    border: 1px solid $c-main-green;
    border-radius: 10rem;
  }
}

.bubbles__item__center {
  @extend %text-center, %text-uppercase, %fw-bold;
  @include center-xy;

  // prettier-ignore
  @include fluid(width,(xxs: 100px,xxl: 180px,));
  // prettier-ignore
  @include fluid(height,(xxs: 100px,xxl: 180px,));
  // prettier-ignore
  @include fluid(border-radius,(xxs: 100px,xxl: 180px,));

  background-color: $c-main-green;

  .bubbles__item__title {
    @extend %text-center, %text-uppercase, %fw-bold;
    @include center-xy;

    color: $c-white;
    font-size: 1.5rem;
  }
}

.bubbles__item__picture-outer {
  // prettier-ignore
  @include fluid(width,(xxs: 60px,xxl: 160px,));
  // prettier-ignore
  @include fluid(height,(xxs: 60px,xxl: 160px,));
  // prettier-ignore
  @include fluid(border-radius,(xxs: 80px,xxl: 160px,));

  position: relative;
  overflow: hidden;
  padding: 1rem;
  border: 2px solid transparent;
  transition: border-color 0.3s $ease-in-out-quad;

  .picture {
    // prettier-ignore
    @include fluid(border-radius,(xxs: 80px,xxl: 160px,));

    min-height: 0 !important;
  }

  .picture-inner {
    // prettier-ignore
    @include fluid(border-radius,(xxs: 80px,xxl: 160px,));

    transition: transform 0.3s $ease-in-out-quad;
  }

  @include mq($until: l) {
    padding: 0;
  }
}

.bubbles__item__icon-outer {
  @include center-xy;

  // prettier-ignore
  @include fluid(width,(xxs: 30px,xxl: 50px,));
  // prettier-ignore
  @include fluid(height,(xxs: 30px,xxl: 50px,));
  // prettier-ignore
  @include fluid(border-radius,(xxs: 30px,xxl: 50px,));

  z-index: 1;
  background-color: $c-main-green;
  opacity: 0;
  transition: opacity 0.3s $ease-in-out-quad;
}

.bubbles__item__icon {
  @include center-xy;

  fill: $c-white;
  transform: translate(-70%, -50%);
  transition: all 0.3s 0.1s $ease-in-out-quad;

  @include mq($until: l) {
    position: relative;
    fill: $c-black;
    transform: none;
    top: 0;
    left: 0;
    margin-right: 3rem;
    margin-left: auto;
  }
}

.bubbles__item__title {
  color: $c-black;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}
