
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































/* stylelint-disable declaration-no-important */

.brochure__thumbail {
  width: 3rem;
  height: auto;
  margin-right: 1.5rem;
}

.brochure {
  display: flex;
  align-items: center;
  border-bottom: 1px $c-black solid;
}

.brochure__title-outer {
  flex-grow: 1;
  padding: 3.3rem 0 2.3rem;
}

.brochure__title {
  font-size: 1.8rem;
  line-height: 1.333;
}

.brochure__title__demand {
  color: $c-main-green;
  font-size: 1.5rem;
}

.brochure__link {
  padding: 0.7rem;
  color: $c-gray-dark;
  line-height: 1;
  text-decoration: none;
  background: $c-gray-light;
  transition: background-color $ease-out $transition-duration;

  &:not(:first-of-type) {
    margin-left: 1.5rem;
  }

  &:focus,
  &:hover {
    color: $c-white;
    background: $c-main-green;
  }
}

.brochure__link__icon {
  fill: $c-gray-medium;
}

.library-listing__head__button {
  &[class*='action--'] {
    padding: 0.7rem 0.3rem !important;
    background-color: $c-gray !important;
    border-radius: 0 !important;
  }

  ::v-deep {
    .action__label {
      display: none;
    }

    .icon {
      fill: $c-gray-dark;
    }
  }

  &:hover {
    &[class*='action--'] {
      background-color: $c-main-green !important;
    }

    ::v-deep {
      .icon {
        fill: $c-white;
      }
    }
  }
}
