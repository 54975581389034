
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.keyfigures-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keyfigures-item__headline {
  color: currentColor;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.333;
  opacity: 0.6;
}

.keyfigures-item__subtitle {
  margin-top: $spacing * 0.5;
  color: currentColor;
  font-size: 1.3rem;
  line-height: 1.38;
  opacity: 0.6;
}

.keyfigures-item__title {
  margin-top: 0;
  color: currentColor;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.333;
}

.keyfigures-item__value {
  @extend %fw-bold;

  color: currentColor;
  font-size: 4.8rem;
  line-height: 1;
}

.keyfigures-item__icon {
  max-width: 5rem;
  margin-bottom: 1rem;
}

.keyfigure-wrapper {
  position: relative;
  padding-top: $spacing * 0.8;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: currentColor;
    opacity: 0.5;
  }
}
