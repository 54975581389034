
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































.table__title {
  @extend %text-center;

  margin-bottom: $spacing * 2;
}
.table__visual {
  cursor: pointer;
}
