
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































.header {
  position: fixed;
  z-index: $header-z-index;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  background-color: $c-white;
  transition: transform 0.3s ease-out;

  &.sticky {
    transform: translateY(-100%);
  }

  &.up {
    transform: initial;
  }

  @include mq($from: xxl) {
    &.is-home:not(.sticky) {
      background-color: transparent;

      .logo__svg {
        fill: $c-white;
      }

      ::v-deep {
        .navigation,
        .dropdown-trigger {
          color: $c-white;
        }

        .dropdown-trigger {
          &:hover {
            .dropdown-trigger__icon--desktop {
              color: $c-white;
            }
          }
        }

        [class*='action--'][class*='--button'] {
          color: $c-black;
          background: $c-white;

          &:hover {
            color: $c-white;
            background: $c-main-green;
          }
        }

        .navigation {
          a:not([class*='--button']):hover {
            color: $c-white;
          }
        }
      }
    }
  }
}

.header__inner {
  // Also used in Megamenu component
  --padding-top: 3rem;
  --padding-bottom: 2rem;

  display: flex;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);

  &.megamenu-opened {
    @include mq($until: l) {
      background-color: $c-white;
      // background: linear-gradient(
      //   180deg,
      //   rgba($c-white, 1) 0%,
      //   rgba($c-white, 0.8) 50%,
      //   rgba($c-white, 0) 100%
      // );
    }
  }

  @include mq($from: $header-breakpoint) {
    --padding-top: 2rem;
    --padding-bottom: 2rem;
  }
}

.logo {
  @include mq($from: $header-breakpoint) {
    // prettier-ignore
    @include fluid(margin-right, (m: 40px, xl: 140px));
  }
}

.logo__svg {
  display: block;
  fill: $c-main-green;
  width: auto;
  height: 4rem;

  @include mq($header-breakpoint) {
    // Logo has vertical offset on desktop.
    // Use margin instead including this spacing in SVG so we can use the same SVG for mobile as well.
    margin-bottom: 2rem;
  }
}

.navigation {
  display: none;
  align-items: center;

  ::v-deep {
    a {
      /* stylelint-disable-next-line declaration-no-important */
      text-decoration: none !important;

      &.router-link-exact-active {
        pointer-events: none;

        &:not([class*='--button']) {
          color: $c-main-green !important;
        }
      }

      &:not(:last-of-type) {
        // prettier-ignore
        @include fluid(margin-right, (l: 20px, xxl: 30px));
      }

      &:not([class*='--button']):hover {
        color: $c-main-green;
      }
    }
  }

  @include mq($from: xxl) {
    display: flex;
    margin-left: auto;
  }
}

.dropdowns {
  display: flex;
  flex-direction: column;

  @include mq($from: xxl) {
    flex-direction: row;
    align-items: center;
  }
}

.megamenu-trigger {
  position: relative;
  display: block;
  width: 3rem;
  margin-left: auto;
  padding: 0;
  background: transparent;
  border-width: 0;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    right: 0;
    left: 0;
    display: block;
    height: 2px;
    background: $c-black;
  }

  &::before {
    margin-bottom: 1rem;
  }

  &.active {
    &::before {
      transform: translateY(6px) rotate(-45deg);
    }

    &::after {
      transform: translateY(-6px) rotate(45deg);
    }
  }

  @include mq($from: xxl) {
    display: none;
  }
}
