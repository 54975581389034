
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































































.product-application-item {
  position: relative;
}

.product-application-item__link {
  width: 100%;
}

.product-application-item__picture {
  position: absolute !important;
  z-index: 999;
  top: -10rem;
  left: -20rem;
  display: block;
  width: 17rem !important;
  height: 22rem !important;
  min-height: 0 !important;
  background-color: $c-main-green;
  clip: rect(0, 170px, 220px, 170px);
  will-change: transform, clip;
  pointer-events: none;
}
