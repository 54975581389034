
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































































.flexible-cta {
  transition: opacity 0.2s;

  ::v-deep {
    .picture {
      height: unset;
      min-height: unset;
    }
  }
}

.flexible-cta__inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include mq(m) {
    flex-direction: row;
  }

  @include mq(l) {
    max-width: col(10, 14);
  }
}

.flexible-cta__picture.picture {
  @include mq(m) {
    width: col(6, 14);
  }

  @include mq(l) {
    width: col(4, 10);
  }
}

.flexible-cta__content {
  position: relative;
  padding: $spacing * 3 col(1, 14);
  background-color: $c-main-beige;

  &.recalculation-tool {
    color: $c-white;
    background-color: $c-equipment-dark;

    ::v-deep {
      .cta {
        color: $c-white;
        background-color: $c-app-mid;

        /* stylelint-disable-next-line max-nesting-depth */
        &:hover {
          background-color: $c-app-dark;
        }
      }
    }
  }

  @include mq(m) {
    width: col(8, 14);
    padding: $spacing * 4 col(1, 10);
  }

  @include mq(l) {
    width: col(6, 10);
  }

  @include mq(xl) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}

.flexible-cta__content__title {
  margin-bottom: $spacing * 2;
}

.close-button {
  position: absolute;
  top: 1.7rem;
  right: 2.2rem;
  width: 2.4;
  height: 2.4;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border-width: 0;
}
