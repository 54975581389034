
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































// Defaults
.action,
[class*='action--'] {
  @extend %button-nostyle;

  position: relative;
  padding-bottom: $spacing * 0.5;
  color: $c-black;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background-color $ease-out $transition-duration;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-black;
    transform-origin: left;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &[class*='--big'] {
    padding-bottom: $spacing;

    &::after {
      background: $c-gray-light;
    }

    & + & {
      margin-top: $spacing * 1.5;
    }
  }

  &:hover {
    .action__icon {
      transform: translateX(1rem);
    }
  }
}

[class*='--icon'] {
  display: inline-flex;

  .action__label {
    // Make sure that label does not touches the icon
    margin-right: 2rem;
    font-weight: 500;
  }

  // Align icon on the right side of link
  .action__icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

// Links
[class*='action--'][class*='--link'] {
  color: inherit;
  text-decoration: none;

  &:hover {
    &:not([class*='--icon']) {
      text-decoration: underline;
    }
  }

  &[class*='--negative'] {
    color: $c-white;
  }

  &[class*='--big'] {
    // prettier-ignore
    @include fluid(font-size, (xxs: 20px, xxl: 24px));

    font-weight: 400;
  }
}

// "Small" links (chrome header, footer...)
[class*='action--'][class*='--link'][class*='--small'] {
  padding: 0;
  color: currentColor;
  font-size: $fs-body;

  &::after {
    content: none;
  }
}

[class*='action--'][class*='--link'][class*='--legal'] {
  padding: 0;
  color: currentColor;
  font-size: 1.3rem;
  text-decoration: none;

  &::after {
    content: none;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--button'] {
  line-height: 1.333;

  &::after {
    content: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:not([class*='--transparent']) {
    padding: 1.2rem 2.8rem;
    color: $c-white;
    font-weight: 500;
    background-color: $c-black;
    border-radius: 6rem;

    &:hover,
    &:focus {
      color: $c-white;
      text-decoration: none;
      background-color: $c-main-green;
    }
  }

  &[class*='--big'] {
    // prettier-ignore
    @include fluid(font-size, (xxs: 20px, xxl: 24px));

    font-weight: 400;
  }

  &[class*='--medium'] {
    // prettier-ignore
    @include fluid(font-size, (xxs: 15px, xxl: 20px));

    font-weight: 500;
  }

  &[class*='--light'] {
    color: $c-black;
    background-color: $c-white;
  }

  &[class*='--outline'] {
    color: $c-black;
    background-color: transparent;
    border: 1px solid $c-gray-medium;
  }
}

.action__icon {
  width: 2.5rem;
  height: 1.5rem;
  fill: currentColor;
  transition: transform 0.2s ease-out;

  &.loading {
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
