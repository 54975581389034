
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































.flexible-video {
  position: relative;

  &.page__section {
    @include default-margin(1, 'margin');

    margin-top: 0;
    padding-bottom: 0;
  }
}

.flexible-video__video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 50%;

  video {
    @include image-fit;
  }
}

.flexible-video__picture {
  @include aspect-ratio(1470, 800);

  min-height: unset;
}

.flexible-video__button {
  @extend %button-nostyle;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  color: $c-white;
  text-align: center;

  @include mq($until: 's') {
    @include get-all-space;
  }

  @include mq(s) {
    position: absolute;
    bottom: $spacing * 3;
    left: $spacing * 5;
    padding: 1.5rem 3rem;
    transform: translate(50%, 0);

    &::after {
      @include get-all-space;

      content: '';
      z-index: -1;
      background: $c-black;
      border-radius: 8rem;
      transition: 0.2s ease-out;
    }

    &:hover::after {
      transform: scale(1.05);
    }
  }
}

.flexible-video__button__icon {
  position: relative;
  display: block;
  width: 3rem;
  height: 3rem;
  background: $c-white;
  border-radius: 50rem;
  transition: transform 0.2s ease-out;

  .icon {
    width: 3rem;
    height: 3rem;
  }

  .flexible-video__button:hover & {
    transform: scale(1.2);
  }

  @include mq(s) {
    margin-left: $spacing * 0.5;

    .icon {
      width: 2rem;
      height: 2rem;
      margin-top: $spacing * 0.25;
    }

    .flexible-video__button:hover & {
      transform: scale(1);
    }
  }
}

.flexible-video__button__label {
  display: none;
  line-height: 1;

  @include mq(s) {
    display: block;
  }

  @include mq(l) {
    font-size: 1.5rem;
  }
}

::v-deep {
  .picture__container {
    height: 0;
    padding-bottom: 50%;
  }

  .picture__caption {
    display: none;
  }
}
