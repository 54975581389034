
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  color: currentColor;
  font-size: 1.5rem;
  line-height: 1.867;
  cursor: pointer;
  transition: background-color 0.25s ease-out;
  text-decoration: none;

  &.light {
    color: $c-white;

    &::after {
      background: currentColor;
    }
  }

  &.big {
    font-size: 2.4rem;
    line-height: 1.166;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-main-green;
  }

  &:hover {
    .link__icon {
      transition-timing-function: $ease-out;

      &.active {
        opacity: 1;
        transform: translate(0%, -50%);
        transition-delay: 100ms;
      }

      &.idle {
        transition-delay: 0ms;
        opacity: 0;
        transform: translate(150%, -50%);
      }
    }
  }
}

.link__label {
  margin-right: 2rem;
}

.link__icon-wrapper {
  position: relative;
  width: 2.5rem;
  height: 1.5rem;
}

.link__icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  transition: all 150ms $ease-in;
  fill: currentColor;

  &.active {
    transition-delay: 0ms;
    opacity: 0;
    transform: translate(-150%, -50%);
  }

  &.idle {
    transition-delay: 100ms;
  }
}
