
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.breadcrumb {
  // border-top: 1px solid $c-gray-medium;
  // border-bottom: 1px solid $c-gray-medium;
}

.breadcrumb__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style-type: none;
}

.breadcrumb__link {
  color: currentColor;
  text-decoration: none;
}

.breadcrumb__item {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.5rem;
  line-height: 2rem;

  &:last-of-type {
    color: $c-main-green;
  }

  &:not(:first-child):not(:last-child) {
    .breadcrumb__link {
      opacity: 0.25;
    }
  }
}

.breadcrumb__chevron {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  fill: currentColor;
  opacity: 0.25;
}
