
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.news-item {
  position: relative;

  &:not(:last-of-type) {
    .news-item__content {
      margin-bottom: 5rem;
    }
  }
}

.news-item__link {
  @include get-all-space;
}

.news-item__content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.news-item__head {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 0;
  color: rgba($c-black, 0.6);
  font-size: 1.5rem;
  line-height: 1;

  @include mq(l) {
    align-items: flex-end;
  }
}

.news-item__head__type {
  line-height: 2.2rem;
}

.news-item__head__date {
  color: $c-gray-medium;
}

.news-item__title {
  width: col(9, 14);
  margin-left: 2.5rem;
  padding: 0 0 1.5rem;
  color: $c-black;
  border-bottom: 1px solid rgba($c-black, 0.1);
  transition: border-bottom $transition-duration $ease-out;

  .news-item:hover & {
    border-bottom: 1px solid rgba($c-black, 1);
  }

  @include mq(l) {
    width: col(5, 8);
    margin-left: 5rem;
  }
}

.news-item__title__link {
  margin-top: $spacing * 0.5;
  color: rgba($c-black, 0.3);
  font-size: 1.3rem;
}
