
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































































































































/* stylelint-disable declaration-no-important */

.duo-picture {
  position: relative;
  color: $c-black;

  &.is-dark {
    padding-top: get-spacing(l, s) !important;
    padding-bottom: get-spacing(l, s) !important;
    color: $c-white;
    background-color: $c-black;

    @include mq(m) {
      padding-top: get-spacing(l, m) !important;
      padding-bottom: get-spacing(l, m) !important;
    }

    @include mq(xl) {
      padding-top: get-spacing(l, l) !important;
      padding-bottom: get-spacing(l, l) !important;
    }
  }
}

.duo-picture-inner {
  @include mq(m) {
    display: flex;
    align-items: center;
  }
}

.duo-picture__picture-outer {
  position: relative;
  overflow: hidden;

  @include mq(m) {
    width: col(6, 14);
  }

  @include mq(l) {
    width: col(4, 14);
    margin-left: col(2, 14);
  }
}

.duo-picture__picture {
  width: auto;
  height: auto;
  max-height: 40vh;

  @include mq(l) {
    width: 100%;
    max-height: unset;
  }
}

.duo-picture__content {
  @include mq(m) {
    width: col(7, 14);
    margin-left: col(1, 14);
  }

  @include mq(l) {
    width: col(5, 14);
    margin-right: col(2, 14);
  }
}

.duo-picture__content__title {
  margin-top: $spacing;

  small {
    @extend %fw-normal;

    font-size: 1em;
  }
}

.duo-picture__content__headline {
  @include mq($until: m) {
    margin-top: $spacing !important;
  }
}

.duo-picture__content__baseline {
  width: 80%;
  margin-top: $spacing * 1.5;
  font-size: 1.8rem;
  opacity: 0.7;
}

.duo-picture__content__text.wysiwyg {
  @extend %fw-normal;

  margin-top: $spacing * 1.5;
  font-size: 1.5rem;

  ol {
    @extend %list-nostyle;

    li {
      padding: $spacing 0;

      & + li {
        margin-top: $spacing;
      }

      h5 {
        margin: 0;
      }

      p {
        margin-top: $spacing * 0.5;
        opacity: 0.8;
      }
    }
  }

  ::v-deep {
    .link {
      @include mq(m) {
        min-width: col(3, 5);
      }
    }
  }
}

.duo-picture__content__link {
  margin-top: $spacing * 3;
}
