
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































































.flexible-advantages {
  position: relative;

  &.page__section {
    // Parent should automatically apply 'page__section' class to component
    // so we override unwanted padding-top and replace padding-bottom with margin-bottom
    // @include default-margin(1, 'margin');

    padding-top: 0;
    padding-bottom: 0;

    // We do not want border on this component. It clashes with component background.
    &::before {
      content: none;
    }
  }
}

.flexible-advantages-inner {
  background-color: $c-equipment-dark;

  .no-image & {
    background-color: $c-main-beige;
  }

  @include mq(m) {
    display: flex;
  }
}

.flexible-advantages__header {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 40px, xxl: 80px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 40px, xxl: 80px));

  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  padding-right: col(1, 16);
  padding-left: col(1, 16);

  &:not(.no-image) {
    color: $c-white;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $c-black;
      opacity: 0.5;
    }
  }

  &.no-image {
    color: $c-black;
  }

  @include mq(m) {
    width: col(7, 16);
  }
}

.flexible-advantages__body {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 40px, xxl: 80px));
  // Same as padding-top but takes into account .keyfigures-item bottom margin
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, xxl: 40px));

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-right: col(1, 16);
  padding-left: col(1, 16);
  // background-color: $c-equipment-dark;

  ::v-deep {
    .keyfigures-grid {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: unset;
      margin-left: unset;
      color: $c-white;

      .keyfigures-grid__item {
        width: 100%;
        margin-top: 0;

        @include mq(l) {
          width: col(3.25, 7);
        }
      }

      @include mq(l) {
        flex-direction: row;
      }
    }

    .flexible-advantages__content__btn {
      margin-top: 1rem;

      @include mq(s) {
        margin-top: 5rem;
      }
    }
  }

  &.no-image {
    background: unset;

    ::v-deep {
      .keyfigures-grid {
        color: $c-black;
      }
    }
  }

  @include mq(m) {
    width: col(9, 16);
  }
}

.flexible-advantages__picture {
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
}

.flexible-advantages__content__baseline {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 20px, xxl: 30px));
}

::v-deep {
  .keyfigures-item {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 40px, xxl: 80px));
  }
}
