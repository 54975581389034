
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































.footer {
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
  background-color: $c-white;

  @include mq($from: l) {
    padding-top: 5.4rem;
    padding-bottom: 1rem;
  }
}

.footer__top {
  display: none;

  @include mq($from: l) {
    display: flex;
  }
}

.logo {
  display: block;
  width: 100%;

  @include mq($from: m) {
    width: 13.4rem;
  }
}

.logo__svg {
  display: block;
  width: 100%;
  fill: $c-main-green;
}

.footer__nav {
  display: flex;
  width: 100%;
  margin-left: auto;

  @include mq($from: m) {
    width: auto;
  }
}

.footer__nav__link-list {
  width: col(3, 7);
  margin: 0;
  padding: 0;
  list-style-type: none;

  &:first-child {
    flex-shrink: 0;
  }

  &:last-child {
    width: auto;
    margin-left: auto;
  }

  @include mq($from: m) {
    width: auto;

    &:not(:last-child) {
      // prettier-ignore
      @include fluid(margin-right, (m: 50px, xl: 100px));
    }
  }
}

.footer__nav__link-list__item {
  padding: 0;

  ::v-deep {
    .router-link-exact-active {
      pointer-events: none;
      color: $c-main-green !important;
    }

    [class*='--button'] {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.footer__legals {
  display: flex;
  flex-wrap: wrap;

  @include mq($from: l) {
    margin-top: 14rem;
  }
}

.footer__legals__copyrights {
  width: 100%;
  margin-bottom: 4rem;
  opacity: 0.3;

  @include mq($from: l) {
    width: auto;
    margin-right: 8rem;
    margin-bottom: 0;
  }
}

.footer__legals-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__legals-nav__entry {
  &:not(:last-child) {
    margin-right: 3rem;
  }
}

.footer__legals-nav__link {
  @extend %button-nostyle;

  display: block;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }
}

.footer__legals__socials {
  display: flex;
  margin-left: auto;
}

.footer__legals__socials__entry {
  color: currentColor;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  &:not(:last-child) {
    margin-right: 3rem;
  }

  svg {
    display: block;
    width: 2.3rem;
    height: 2.3rem;
    fill: currentColor;
  }
}
