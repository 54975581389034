
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































































/* stylelint-disable declaration-no-important */

.flexible-product-application__title {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 20px, xxl: 50px));
}

.flexible-product-application-inner {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    margin-left: auto;
  }

  @include mq(l) {
    width: col(10, 14);
  }
}

.flexible-product-application__items {
  width: col(16);

  @include mq(m) {
    width: col(4);
  }
}

.flexible-product-application__item__headline {
  margin-bottom: $spacing * -0.5;
  color: rgba($c-black, 0.4);

  @include mq(m) {
    margin-bottom: $spacing;
  }
}
