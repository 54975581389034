
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































































































































































.testimonials {
  @include mq($until: l) {
    overflow: hidden;

    .activities-slider__header,
    .activities-slider__slideshow {
      width: 87.5%;
      margin-right: col(1, 16);
      margin-left: col(1, 16);
    }
  }
}

.testimonials__header {
  margin-bottom: 5rem;

  @include mq($from: l) {
    width: col(5, 14);
  }
}

.testimonials__baseline {
  margin-bottom: 4rem;
}

.testimonials__slideshow {
  display: flex;
  flex-flow: column-reverse wrap;

  @include mq($from: l) {
    flex-direction: row;
  }
}

.testimonials__slideshow__nav {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include mq($from: l) {
    flex-direction: column;
    width: col(5, 14);
    margin: 0 col(2, 14) 0 0;
  }
}

.testimonials__slideshow__nav__entry {
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 2.5rem;

    @include mq($from: l) {
      margin-right: 0;
      margin-bottom: 2.5rem;
    }
  }
}

.testimonials__button {
  --progress: 0;
  --transform-origin: 0 0;

  position: relative;
  width: 100%;
  padding: 0;
  text-align: left;
  background-color: transparent;
  border-bottom: 1px $c-gray-dark solid;
  border-width: 0;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 2px;
    background-color: $c-main-green;
    transform-origin: var(--transform-origin, 0 0);
    transform: scaleX(var(--progress, 0));
  }
}

.testimonials__button__name {
  margin-bottom: 0.2rem;
}

.testimonials__button__job {
  margin-bottom: 0.8rem;
  color: $c-gray-dark;
  line-height: 1.538;
}

.testimonials__slideshow__content {
  width: 100%;
  margin-bottom: 2rem;

  ::v-deep {
    .flexible-video {
      max-width: unset;
      height: 100%;

      &.wrapper {
        padding: 0;
      }
    }

    .flexible-video__button {
      @include mq($from: s) {
        bottom: 5.2rem;
        left: 3.5rem;
        transform: translate(0, 0);
      }
    }

    .flexible-video__button__icon {
      @include mq($from: s) {
        width: 2rem;
        height: 2rem;

        .icon {
          display: block;
          margin-top: 0;
        }
      }
    }

    .picture {
      @include mq($from: l) {
        min-height: unset;
      }
    }
  }

  @include mq($until: l) {
    aspect-ratio: 3/2;

    ::v-deep {
      .picture {
        min-height: unset;
      }
    }
  }

  @include mq($from: l) {
    width: col(7, 14);
    margin-bottom: 0;
  }
}
