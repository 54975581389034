
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































.contact-information {
  display: flex;
  flex-direction: column;

  @include mq(s) {
    flex-direction: row;
  }
}

.contact-information__title {
  margin-bottom: 3rem;

  @include mq(s) {
    width: col(4, 14);
    margin-bottom: 0;
  }
}

.contact-information__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include mq(s) {
    width: col(10, 14);
  }

  @include mq(l) {
    width: col(7, 14);
  }
}

.contact-information__item {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  border-bottom: 1px rgba($c-black, 0.15) solid;

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  @include mq(s) {
    flex-direction: row;
  }
}

.contact-information__item__column {
  display: flex;
  flex-direction: column;

  @include mq($until: s) {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  @include mq(s) {
    width: col(5, 10);
  }

  @include mq(l) {
    &:first-of-type {
      width: col(3, 7);
    }

    &:last-of-type {
      width: col(4, 7);
    }
  }
}

.contact-information__item__subtitle {
  opacity: 0.5;
}

.contact-information__item__link {
  color: currentColor;
  text-decoration: none;
}
