
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































$svg-icon-width: 2.4rem;

.rl__content {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 20px, xxl: 180px));

  @include mq($from: l) {
    width: col(5, 14);
  }
}

.rl__visual-container {
  display: none;

  @include mq($from: l) {
    display: block;
    width: col(7, 14);
    margin-left: col(2, 14);
  }
}

.rl__baseline {
  margin-bottom: 4rem;
}

.rl__title {
  margin-bottom: 4rem;
}

.rl__text {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 40px, xxl: 100px));
}

.rl__list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include mq($from: l) {
    display: flex;
    flex-direction: column;
  }
}

.rl__list-item {
  flex-shrink: 0;
  margin-bottom: 4rem;

  @include mq($from: l) {
    margin-right: 0;
    margin-bottom: 2.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.rl__trigger {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 2rem;
  color: inherit;
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  border-bottom: 1px rgba($c-black, 0.1) solid;
  border-width: 0;

  &:is(a) {
    cursor: pointer;
  }

  @include mq(l) {
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      height: 2px;
      background-color: $c-main-green;
      transform-origin: 100% 0;
      transform: scaleX(0);
      transition: transform $transition-duration $ease-out;
    }

    &.active {
      &::after {
        transform-origin: 0 0;
        transform: scaleX(1);
      }

      .rl__trigger__svg {
        opacity: 1;
      }
    }
  }
}

.rl__trigger__text-content {
  position: relative;
  margin-bottom: 0.8rem;
  padding-right: $svg-icon-width + 1rem; // Add 1rem safe space
}

.rl__trigger__title {
  margin-bottom: 0.2rem;
}

.rl__trigger__subtitle {
  color: $c-gray-dark;
  line-height: 1.538;
}

.rl__trigger__svg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  fill: inherit;
  width: $svg-icon-width;
  opacity: 0.3;
  transition: opacity $transition-duration $ease-out;
}

.rl__cta {
  // prettier-ignore
  @include fluid(margin-top, (xxs: 20px, xxl: 90px));
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 20px, xxl: 90px));

  display: inline-flex;

  ::v-deep {
    .action__icon-wrapper {
      margin-left: auto;
    }
  }

  @include mq($from: l) {
    min-width: col(3, 5);
    margin-bottom: 0;
  }
}

.rl__trigger__picture.picture {
  overflow: unset;
  width: unset;
  height: unset;
  min-height: unset;

  ::v-deep {
    .picture-inner {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      width: 100%;
      height: auto;
      max-height: inherit;
    }
  }

  @include mq(l) {
    display: none;
  }
}
