
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































.details {
  border-bottom: 1px currentColor solid;

  &[open] {
    .details__summary__icon {
      transform: rotate(90deg);

      .line {
        &:nth-of-type(1) {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
}

.details__summary {
  cursor: pointer;
  list-style: none;
  user-select: none;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
}

// Additional wrapper is needed because display: flex does not work on summary elements in safari
.details__summary__inner {
  display: flex;
  align-items: center;
}

.details__summary__thumbnail {
  width: 5rem;
  margin-right: 2rem;
}

.details__summary__title {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, xxl: 40px));

  flex-grow: 1;
}

.details__summary__icon {
  // prettier-ignore
  @include fluid(width, (xxs: 20px, xxl: 30px));

  position: relative;
  margin-left: 2rem;
  transition: transform $ease-out $transition-duration;

  .line {
    // prettier-ignore
    @include fluid(width, (xxs: 20px, xxl: 30px));

    position: absolute;
    top: 50%;
    left: 50%;
    height: 2px;
    background-color: currentColor;
    transform: translate(-50%, -50%);

    &:nth-of-type(1) {
      transition: transform $ease-out $transition-duration;
    }

    &:nth-of-type(2) {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.details__content {
  margin: 0 auto;
  max-width: $flexible-wysiwyg-max-width;
}

details,
summary {
  &:focus {
    outline: none;
  }
}

::v-deep {
  .wysiwyg.details__content {
    > *:last-child {
      margin-bottom: 5.4rem;
    }
  }
}
