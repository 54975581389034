
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










.baseline {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;

  &::after {
    @include center-y;

    content: '';
    left: 0;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $c-main-green;
    border-radius: 0.5rem;
  }
}
