
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.slideshow {
  --slide-spacing: 4rem;

  overflow: hidden;
}

.slideshow__track {
  display: flex;
}

.slideshow__slide {
  flex-shrink: 0;
  width: 50vw;
  margin-right: var(--slide-spacing);

  &:last-child {
    margin-right: 0;
  }

  @include mq($from: l) {
    width: 25vw;
  }
}
