
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































































































/* stylelint-disable declaration-no-important */

.duo {
  position: relative;
  color: $c-black;

  &.is-dark {
    padding-top: get-spacing(l, s) !important;
    padding-bottom: get-spacing(l, s) !important;
    color: $c-white;
    background-color: $c-black;

    @include mq(m) {
      padding-top: get-spacing(l, m) !important;
      padding-bottom: get-spacing(l, m) !important;
    }

    @include mq(xl) {
      padding-top: get-spacing(l, l) !important;
      padding-bottom: get-spacing(l, l) !important;
    }
  }
}

.duo-inner {
  @include mq(m) {
    display: flex;
  }
}

.duo__header {
  position: relative;

  @include mq(m) {
    width: col(5, 14);
  }
}

.duo__header__headline {
  margin-bottom: 2rem;
}

.duo__header__title {
  small {
    @extend %fw-normal;

    font-size: 1em;
  }
}

.duo__header__baseline {
  width: 80%;
  margin-top: $spacing * 1.5;
  font-size: 1.8rem;
  opacity: 0.7;
}

.duo__header__picture {
  margin-top: 5rem;
}

.duo__content {
  @extend %fw-normal;

  font-size: 1.5rem;

  @include mq($until: m) {
    margin-top: $spacing * 1.5;
  }

  @include mq(m) {
    width: col(7, 14);
    margin-left: col(2, 14);
  }

  ol {
    @extend %list-nostyle;

    li {
      padding: $spacing 0;

      & + li {
        margin-top: $spacing;
      }

      h5 {
        margin: 0;
      }

      p {
        margin-top: $spacing * 0.5;
        opacity: 0.8;
      }
    }
  }

  .wysiwyg {
    .link {
      @include mq(m) {
        min-width: col(3, 7);
      }
    }
  }
}

.duo__content__link {
  display: inline-block;
  margin-top: $spacing * 3 !important;
}
