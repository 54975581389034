
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.related-jobs {
  @include mq(l) {
    display: flex;
  }
}

.related-jobs__header {
  margin-bottom: 4rem;

  @include mq(l) {
    width: col(5, 14);
    margin-bottom: 0;
  }
}

.related-jobs__body {
  @include mq(l) {
    width: col(9, 14);
  }
}

.job-listing {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 30px, xxl: 90px));
}

.link {
  @include mq(l) {
    margin-left: col(2, 9);
    min-width: col(3, 9);
  }
}
