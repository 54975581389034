
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































































/* stylelint-disable declaration-no-important */

.solo {
  @include default-margin;

  position: relative;
  color: $c-black;

  &.is-dark {
    padding-top: get-spacing(l, s) !important;
    padding-bottom: get-spacing(l, s) !important;
    color: $c-white;
    background-color: $c-black;

    @include mq(m) {
      padding-top: get-spacing(l, m) !important;
      padding-bottom: get-spacing(l, m) !important;
    }

    @include mq(xl) {
      padding-top: get-spacing(l, l) !important;
      padding-bottom: get-spacing(l, l) !important;
    }
  }
}

.solo__headline {
  position: absolute;
  top: 0;
  margin-bottom: 2rem;
}

.solo__title {
  margin-bottom: 5rem;
}

.solo__content {
  @include mq($from: s) {
    width: col(11, 14);
  }

  @include mq($from: l) {
    width: col(8, 14);
  }
}

.solo__content__link {
  display: inline-block;
  margin-top: $spacing * 1.5;
}
