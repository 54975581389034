
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































.breaking-news {
  position: fixed;
  z-index: $header-z-index + 1;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $c-black;
    opacity: 0.4;
    pointer-events: none;
  }
}

::v-deep {
  .flexible-cta.wrapper {
    width: auto;
    padding: 0;
  }

  .flexible-cta__content {
    margin: auto;
  }
}
