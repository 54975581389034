
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































































































































































































































.video-popup {
  @include get-all-space;

  position: fixed;
  z-index: 999;
  background: $c-white;
}

.video-popup__button {
  position: absolute;
  top: $spacing;
  right: $spacing * 1.5;
  align-items: center;

  ::v-deep {
    .action__label,
    .action__icon-wrapper {
      display: flex;
    }

    .action__icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

.video-popup__content {
  @include center-xy;

  width: 100%;
  background: $c-black;
}

.video-popup__content__video,
.video-popup__content__video-embed {
  display: block;
  min-height: 30rem;
  margin: 0 auto;

  @include mq(xl) {
    aspect-ratio: 16/9;
  }
}

.video-popup__controls {
  @include center-x;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing $spacing * 2 5vh;
}

.video-popup__controls__button {
  @extend %button-nostyle;

  display: flex;
  align-items: center;
  width: 4rem;
  height: 4rem;
  color: $c-black;
  transition: color 0.1s;

  &:hover {
    color: lighten($c-black, 20%);
  }
}

.pause-line {
  display: inline-block;
  width: 4px;
  height: 20px;
  margin: 0 2px 1px;
  background: currentColor;
}
