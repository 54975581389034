
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































































































































































































































/* stylelint-disable declaration-no-important */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
/* stylelint-disable max-nesting-depth */

.megamenu-container {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background: $c-white;

  &.active {
    display: flex;
  }

  &.level-0,
  &.level-1 {
    .megamenu__desktop-dropdown__svg-border {
      display: none;

      @include mq($from: xxl) {
        display: unset;
      }
    }
  }

  &.level-0 {
    .megamenu__desktop-dropdown {
      display: none;

      @include mq($from: xxl) {
        display: unset;
      }
    }
  }

  @include mq($from: xxl) {
    position: unset;
    z-index: 0;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    display: flex;
    background: transparent;
  }
}

.dropdowns {
  display: flex;
  flex-direction: column;

  ::v-deep {
    .dropdown-trigger {
      // prettier-ignore
      @include fluid(margin-right, (l: 25px, xxl: 50px));

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include mq($from: xxl) {
    flex-direction: row;
  }
}

.dropdown-trigger {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 5rem;
  padding: 0;
  padding-bottom: 2.2rem;
  text-align: left;
  background: transparent;
  border-bottom: 1px $c-gray solid;
  border-width: 0;
  cursor: pointer;

  @include mq($from: xxl) {
    margin-bottom: unset;
    padding-bottom: 0;
    border-bottom-width: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: calc(var(--padding-bottom) * -1);
      width: 100%;
      height: 2px;
      background: $c-main-green;
      transform: scaleX(0);
    }

    &:hover {
      .dropdown-trigger__icon--desktop {
        color: $c-main-green;
      }
    }

    &.active {
      &::after {
        transform: scaleX(1);
      }

      .dropdown-trigger__icon--desktop {
        color: $c-main-green;
      }
    }
  }
}

.dropdown-trigger__label {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 4rem;

  @include mq($until: xxl) {
    color: $c-black;
  }

  @include mq($from: xxl) {
    // This element is designed to use h4 but layout breaks around lower L breakpoint
    // so we're slightly overiding font size to avoid responsive issues.
    // prettier-ignore
    @include fluid(font-size, (l: 16px, xxl: 18px));

    white-space: nowrap;
  }
}

[class*='dropdown-trigger__icon'] {
  display: block;
  fill: currentColor;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: auto;

  &[class*='--desktop'] {
    display: none;
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.5rem;

    @include mq($from: xxl) {
      display: block;
    }
  }

  @include mq($from: xxl) {
    display: none;
  }
}

.megamenu__desktop-dropdown {
  --c1: #{$c-app-light};
  --c2: #{$c-app-mid};
  --c3: #{$c-app-dark};

  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &.processes {
    --c3: #{$c-processes-dark};
    --c2: #{$c-processes-mid};
    --c1: #{$c-processes-light};
  }

  &.equipment {
    --c3: #{$c-equipment-dark};
    --c2: #{$c-equipment-mid};
    --c1: #{$c-equipment-light};

    .subcategory {
      position: relative;
      width: 100%;
      height: 14rem;

      .subcategory__link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        color: currentColor;
        text-decoration: none;
        background-color: $c-gray-lighter;

        &.router-link-exact-active {
          pointer-events: none;
          .subcategory__link__picture {
            opacity: 0.3;
          }
        }
      }

      .subcategory__link__label {
        position: absolute;
        z-index: 1;
        top: 1.5rem;
        left: 2.2rem;
        font-size: 1.8rem;
      }

      @include mq($until: xxl) {
        padding-right: 0;
      }

      @include mq($from: xxl) {
        width: 50%;
      }

      @include mq($from: 1330px) {
        width: 25%;
        min-height: 30.8rem;
      }
    }

    .subcategory__link__picture {
      min-height: unset;

      ::v-deep {
        /* stylelint-disable-next-line max-nesting-depth */
        img {
          object-fit: contain;
          object-position: 100% 100%;

          /* stylelint-disable-next-line max-nesting-depth */
          @include mq($from: xxl) {
            transform: scale(0.85);
            transform-origin: 100% 100%;
          }
        }
      }

      @include mq($from: 1330px) {
        position: absolute;
        bottom: 0;
        height: unset;
        max-height: 100%;

        /* stylelint-disable-next-line max-nesting-depth */
        ::v-deep {
          img {
            position: unset;
            top: unset;
            right: unset;
            bottom: unset;
            left: unset;
            height: unset;
            transform: scale(0.85);
            transform-origin: 100% 100%;
          }
        }
      }

      &:hover {
        ::v-deep {
          img {
            transform: scale(1);
          }
        }
      }
    }
  }

  &.products-applications {
    .subcategory {
      @include mq($from: xxl) {
        width: 33%;
      }
    }
  }

  @include mq($from: xxl) {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: auto;
    background-color: $c-white;
    border-top: transparentize($c-black, 0.9) 1px solid;
  }
}

.megamenu__desktop-dropdown__svg-border {
  // prettier-ignore
  @include fluid(height, (xxs: 3px, xxl: 5px));

  display: block;
  width: 100%;

  rect {
    &:nth-child(1) {
      fill: var(--c1);
    }

    &:nth-child(2) {
      fill: var(--c2);
    }

    &:nth-child(3) {
      fill: var(--c3);
    }
  }
}

.megamenu__column {
  @include mq($from: xxl) {
    // prettier-ignore
    @include fluid(padding-top, (l: 30px, xxl: 50px));
    // prettier-ignore
    @include fluid(padding-bottom, (l: 30px, xxl: 50px));
  }
}

.megamenu__level-0 {
  overflow: auto;
  width: 100%;
  padding: $header-height col(1, 16) 2rem col(1, 16);

  @include mq($from: xxl) {
    display: flex;
    overflow: unset;
    width: auto;
    padding: unset;
  }
}

.megamenu__level-1 {
  @include mq($until: xxl) {
    width: 100%;
    padding-top: $header-height;
    padding-bottom: 0;

    .category-megamenu {
      margin-bottom: 5rem;
      padding-bottom: 2.2rem;
      color: $c-black;
      border-bottom: $c-gray 1px solid;

      &.active {
        .category__link {
          display: none;
        }
      }

      &::after {
        content: none;
      }

      .category__button {
        margin-bottom: 0;
        font-size: 3rem;
        font-weight: 300;
        line-height: 4rem;
      }
    }
  }

  @include mq($from: xxl) {
    width: col(3.5, 14);
    padding-right: unset;
    padding-left: unset;
  }

  @include mq($from: xl) {
    width: col(3, 14);
  }
}

.megamenu__level-2 {
  @include mq($until: xl) {
    width: 100%;
    padding-top: $header-height;
  }

  @include mq($from: xl) {
    width: col(14, 14);
  }

  @include mq($from: xxl) {
    display: flex;
    width: col(10.5, 14);
  }
}

.category-megamenu {
  position: relative;
  margin-bottom: 2rem;
  padding-right: 2rem;
  color: $c-gray-medium;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: col(3, 4);
    height: 2px;
    background: $c-main-green;
    transform: scaleX(0);

    @include mq($from: xl) {
      width: col(2, 3);
    }
  }

  &.active {
    color: $c-black;

    .category__link {
      position: static;
      display: inline-flex;

      &.router-link-exact-active {
        pointer-events: none;
        color: $c-main-green;
      }

      &::before {
        @include get-all-space;

        content: '';
        display: block;
      }
    }

    &::after {
      transform: scaleX(1);
    }
  }

  ::v-deep {
    .action__icon-wrapper {
      margin-left: 0;
    }
  }
}

.category__button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0;
  color: inherit;
  text-align: left;
  background: transparent;
  border-width: 0;
  cursor: pointer;

  @include mq($from: xxl) {
    display: block;
  }
}

.category__button__svg {
  flex-shrink: 0;
  fill: currentColor;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: auto;

  @include mq($from: xxl) {
    display: none;
  }
}

.category__link {
  display: none;
  margin-bottom: 3rem;

  &[class*='action--'][class*='--button'][class*='--medium'] {
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
    line-height: 1.2;

    &:hover {
      ::v-deep {
        .action__icon-wrapper {
          background-color: $c-main-green;
        }
      }
    }

    ::v-deep {
      .action__icon-wrapper {
        display: flex;
        align-items: center;
        width: 1.8rem;
        height: 1.8rem;
        background: currentColor;
        border-radius: 50%;
        transition: background-color $ease-out $transition-duration;
      }

      .action__icon {
        display: block;
        width: 100%;
        height: 100%;
        fill: $c-white;
        transform: scale(1.25);
      }

      .action__label {
        margin-right: 0.8rem;
      }
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.subcategories {
  display: flex;
  flex-wrap: wrap;

  @include mq($from: xxl) {
    width: col(7.5, 10.5);
  }

  @include mq($from: xl) {
    width: col(8.5, 11);
  }
}

.subcategories__category-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5.5rem;
  color: currentColor;
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 4rem;
  text-decoration: none;

  &:hover {
    .subcategories__category-title__overview-svg-wrapper {
      background: $c-main-green;
    }
  }

  .category__link {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .subcategories__category-title__overview {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    font-size: 2rem;
    line-height: 2rem;
  }

  .subcategories__category-title__overview-svg-wrapper {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.8rem;
    background: currentColor;
    border-radius: 50%;
    transition: background-color $ease-out $transition-duration;
  }

  .subcategories__category-title__overview-svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: $c-white;
    transform: scale(1.25);
  }

  // -label
  // -svg

  @include mq($from: xxl) {
    display: none;
  }
}

.subcategory {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3.7rem;
  padding-right: 2rem;

  .small {
    margin-bottom: 1.8rem;
    color: $c-gray-medium;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include mq($until: xxl) {
      font-size: 1.5rem;
    }
  }

  .category__link {
    display: flex !important;
  }

  .action--small----link {
    margin-bottom: 1rem;
    line-height: 2.2rem;

    &.router-link-exact-active {
      pointer-events: none;
      color: $c-main-green;
    }

    @include mq($until: xxl) {
      font-size: 1.8rem !important;
    }
  }

  @include mq($until: $header-breakpoint) {
    a[class*='action'] {
      display: inline-block;
      margin-bottom: 1rem;
      line-height: 2.8rem;
    }
  }

  @include mq($from: xs) {
    width: 50%;
  }

  @include mq($from: xxl) {
    width: 33.333%;
  }
}

.subcategories--last-column {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $c-white;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: -50vw;
    left: -50vw;
    height: 100%;
    background-color: var(--c3);
    pointer-events: none;
  }

  ::v-deep {
    a {
      // prettier-ignore
      @include fluid(font-size, (xxs: 18px, xxl: 18px));

      display: flex;
      align-items: center;
      padding: 0;
      font-weight: 400;
      line-height: 1.3;

      &.router-link-exact-active {
        pointer-events: none;
        color: $c-equipment-light;
      }

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }

      &::after {
        content: none;
      }

      .action__icon-wrapper {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
      }

      .action__label {
        margin-right: 0;
      }

      .action__icon {
        margin-left: 0;
        width: 2.6rem;
        height: 2.6rem;
      }
    }
  }

  @include mq($until: xxl) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  @include mq($from: xl) {
    width: col(11, 11);
    padding-left: col(0.5, 11);
  }

  @include mq($from: xxl) {
    width: col(3, 10.5);
    padding-left: 2rem;

    &::after {
      right: unset;
      left: 0;
      width: 100vw;
    }
  }
}

.go-back-button {
  display: flex;
  align-items: center;
  margin-bottom: 4.2rem;
  padding: 0;
  color: $c-gray-dark;
  background: transparent;
  border-width: 0;
  cursor: pointer;

  @include mq($from: xxl) {
    display: none;
  }
}

.go-back-button__svg {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
  fill: currentColor;
}

.mobile-links-list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include mq($from: xxl) {
    display: none;
  }
}

.mobile-links-list__entry {
  margin-bottom: 1.5rem;
  padding: 0;

  .mobile-link {
    &[class*='action--'][class*='--link'][class*='--small'] {
      font-size: 2.2rem;
      line-height: 3.6rem;

      &:not([class*='--button']) {
        color: $c-gray-dark;
      }
    }

    &[class*='--button'] {
      display: inline-block;
      margin-top: 4.5rem;
    }
  }
}

.subcategory__child {
  &.calculator {
    margin-top: 1.5rem;
  }
}
