
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.wysiwyg {
  max-width: $flexible-wysiwyg-max-width;
  margin: 0 auto;
}
