
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































.keyfigures-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 40rem;
  margin-left: auto;
}

.keyfigures-grid__item {
  & + & {
    margin-top: $spacing * 1.5;
  }
}
