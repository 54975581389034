
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































































































































































$breakpoint-tab-content-l-plus: 1250px;

.slider-container {
  @include mq($until: s) {
    overflow: hidden;
  }
}

.slider {
  &.autoplay {
    .slider__tab-trigger.active {
      transition-duration: 1ms;
    }
  }

  &:not(.autoplay) {
    .slider__tab-trigger.active {
      --progress: 1;
      --transform-origin: 0 0;
    }
  }

  &.navigation-left {
    .slider__tabs-list {
      position: relative;
      display: inline-flex;
      max-width: 100%;
      margin-bottom: 3rem;

      @include mq(s) {
        display: block;
        width: col(3, 14);
        margin-bottom: 0;
        padding: col(0);
      }

      @include mq(l) {
        width: col(2.5, 14);
      }
    }

    .slider__tabs-list__item {
      @include mq($until: s) {
        position: relative;
        display: flex;
        box-sizing: content-box;
        width: 40%;
        // max-width: 14rem;
        padding: 0 2rem 0 0;

        &:last-of-type {
          padding-right: 0;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background: $c-gray-light;
        }
      }

      @include mq(s) {
        margin-bottom: 2rem;
      }
    }

    .slider__active-tab {
      width: 100%;

      @include mq(s) {
        display: flex;
        width: col(10, 14);
        margin-left: col(1, 14);
      }

      @include mq(l) {
        display: flex;
        width: col(10, 14);
        margin-left: col(1.5, 14);
      }
    }

    .slider__active-tab__wysiwyg {
      width: 100%;
      margin-bottom: 5rem;

      @include mq(s) {
        width: col(5, 10);
        margin-bottom: 0;
      }

      @include mq($breakpoint-tab-content-l-plus) {
        width: col(4, 10);
      }
    }

    .slider__active-tab__visual-container {
      width: 100%;

      @include mq(s) {
        width: col(4, 10);
        margin-left: col(1, 10);
      }

      @include mq($breakpoint-tab-content-l-plus) {
        width: col(5, 10);
      }
    }

    @include mq(s) {
      display: flex;
    }
  }

  &.navigation-top {
    .slider__tabs-list {
      margin-bottom: 3rem;

      @include mq(m) {
        margin-bottom: 7.5rem;
      }
    }

    .slider__tabs-list__item {
      flex-grow: 1;
    }

    .slider__tab-trigger {
      padding-right: 3rem;
    }

    .slider__active-tab {
      @include mq(m) {
        display: flex;
        align-items: center;
      }
    }

    .slider__active-tab__wysiwyg {
      margin-bottom: 5rem;

      @include mq(m) {
        flex-shrink: 0;
        width: col(6, 14);
        margin-bottom: 0;
      }
    }

    .slider__active-tab__visual-container {
      @include mq(m) {
        @include aspect-ratio(2, 1.25);

        position: sticky;
        top: $header-height;
        width: col(7, 14);
        margin-left: col(1, 14);

        img {
          height: 100%;
        }
      }
    }
  }
}

.slider__tabs-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.slider__tabs-list__icon {
  position: absolute;
  top: 0;
  right: 2rem;

  @include mq(m) {
    display: none;
  }
}

.slider__tabs-list__item {
  flex-shrink: 0;
  padding: 0;
  line-height: 1.2;
}

.slider__tab-trigger {
  position: relative;
  width: 100%;
  padding: 0 0 1rem;
  color: inherit;
  font-size: 1.5rem;
  text-align: left;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 0;
    height: 2px;
    background-color: $c-main-green;
    transform: scaleX(var(--progress, 0));
    transform-origin: var(--transform-origin, 100% 0);
    transition: transform $transition-duration $ease-out;
  }
}

.slider__tab-trigger__subtitle {
  color: $c-gray-medium;
}

.slider__active-tab__visual-container {
  display: flex;

  img {
    object-fit: cover;
  }
}
