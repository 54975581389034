
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 50rem;
  background-color: $c-gray-lighter;
}

.picture-inner {
  @include image-fit;

  transition: transform 0.2s ease-out;
}
