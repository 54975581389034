// IMPORT GOOGLE FONTS
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

// @font-face {
//   font-family: 'SpaceMono';
//   src: url('/assets/fonts/SpaceMono-Regular.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Swis721BT';
//   src: url('/assets/fonts/Swis721BT/Swis721BT-Thin.woff2') format('woff2'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Thin.woff') format('woff'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Thin.ttf') format('truetype');
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Swis721BT';
//   src: url('/assets/fonts/Swis721BT/Swis721BT-Light.woff2') format('woff2'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Light.woff') format('woff'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Light.ttf') format('truetype');
//   font-weight: 200;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Swis721BT';
//   src: url('/assets/fonts/Swis721BT/Swis721BT-Roman.woff2') format('woff2'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Roman.woff') format('woff'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Roman.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Swis721BT';
//   src: url('/assets/fonts/Swis721BT/Swis721BT-Medium.woff2') format('woff2'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Medium.woff') format('woff'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Swis721BT';
//   src: url('/assets/fonts/Swis721BT/Swis721BT-Bold.woff2') format('woff2'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Bold.woff') format('woff'),
//     url('/assets/fonts/Swis721BT/Swis721BT-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }

html {
  font-size: calc(1em * 0.625);
}

body {
  color: $c-body;
  font-family: $ff-default;
  font-size: $fs-body;
  font-weight: 300;
  line-height: 1.9;

  &.viewer-open {
    // Disable inline padding set by v-viewer
    /* stylelint-disable-next-line declaration-no-important */
    padding-right: unset !important;
  }

  @include mq(m) {
    font-size: $fs-body-m;
  }
}
