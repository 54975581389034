.cookiebar {
  --cookiebar-c-primary: #{$c-black};
  --cookiebar-c-secondary: #{$c-black};
  --cookiebar-c-secondary-light: #{$c-gray-light};
  --cookiebar-c-text: #{$c-black};
  --cookiebar-c-active: #{$c-processes-mid};

  position: relative;
  z-index: 1000 !important;
  background: rgba(0, 0, 0, 0.5) !important;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $c-black;
    opacity: 0.3;
    pointer-events: none;
  }

  .cookiebar-controls {
    @include center-x;

    position: fixed;
    right: unset;
    margin-left: 0;

    .cookiebar__title {
      display: block;
      margin-bottom: 0.8rem;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .cookiebar__text {
      color: $c-gray-dark;
      font-size: 1.3rem;
      line-height: 2rem;

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      a {
        color: currentColor;
      }
    }
  }

  .cookiebar-controls__split {
    background-color: $c-gray-light;
  }

  .cookiebar__actions {
    justify-content: space-between;

    [class*='cookiebar__action'] {
      svg {
        display: none;
      }
    }

    [class*='--hollow'] {
      padding: 0;
      color: $c-black;
      font-size: 1.3rem;
      line-height: 2rem;
      border-width: 0;
    }

    [class*='--shadow'] {
      color: $c-white;
      font-size: 1.5rem;
      line-height: 2rem;
      background: $c-app-mid;
      border-width: 0;
      box-shadow: none;
    }
  }

  .cookiebar-selection {
    position: relative;
    overflow: auto;
  }

  .cookiebar-selection__footer::before {
    content: none;
  }

  &.is-fullscreen {
    z-index: 1000 !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  // undo the .h2 style
  .cookiebar__item__header__title.h2 {
    margin-left: 0.5rem;
    font-family: unset;
    font-size: unset;
    font-weight: 500;
  }

  // undo the .h3 style
  .h3 {
    margin-left: 0.5rem;
    font-family: unset;
    font-size: unset;
    font-weight: 500;
  }
}
