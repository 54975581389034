
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































$custom-select-caret-size: 2.4rem;
$custom-select-caret-spacing: 0.9rem;

.custom-select {
  --icon-size: #{$custom-select-caret-size};
  --icon-spacing: #{$custom-select-caret-spacing};

  position: relative;
  display: flex;
  align-items: center;
}

.custom-select__select {
  width: 100%;
  padding-right: calc(
    $custom-select-caret-size + $custom-select-caret-spacing * 2
  );
  background-color: transparent;
  border-width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom-select__caret {
  position: absolute;
  top: 50%;
  right: $custom-select-caret-spacing;
  display: block;
  transform: translateY(-50%);
  width: $custom-select-caret-size;
  height: $custom-select-caret-size;
  pointer-events: none;
}
