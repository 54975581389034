// FORMS

.form__head {
  background-color: $c-equipment-dark;

  .baseline {
    color: $c-white;
  }
}

.form__head,
.form__body {
  padding-right: col(1);
  padding-left: col(1);

  @include mq(m) {
    padding-right: col(2);
  }
}

.form__body {
  padding-top: $spacing * 2;

  @include mq(m) {
    padding-top: $spacing * 3;
  }

  @include mq(xl) {
    padding-top: $spacing * 5;
  }
}

.form__baseline {
  margin-bottom: $spacing * 2;

  @include mq(l) {
    margin-bottom: $spacing * 3;
  }
}

.form-label {
  @include center-y;

  left: 0;
  color: $c-gray-medium;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
  transition: 0.2s ease-out;
  transition-property: color, transform, font-size;

  &.is-filled {
    color: $c-main-green;
    font-size: 1.1rem;
    font-weight: 400;
    transform: translateY(-3rem);
  }

  @include mq($until: m) {
    color: $c-main-green;
    font-size: 1.1rem;
    font-weight: 400;
    transform: translateY(-3rem);
  }
}

.form-feedback {
  @extend %text-uppercase;

  position: absolute;
  bottom: -1rem;
  color: $c-dev-error;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: .05rem;
  transform: translateY(100%);
}

.form-confirmation {
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-bottom: $spacing * 3;
  }

  @include mq(xl) {
    padding-bottom: $spacing * 5;
  }
}