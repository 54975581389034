
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































.jobs__header {
  margin-bottom: 5rem;

  @include mq($from: l) {
    width: col(5, 14);
  }
}

.jobs__locations {
  --icon-spacing: 0;
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 50px, xxl: 100px));

  position: relative;
  width: fit-content;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -0.7rem;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: transparentize($c-black, 0.75);
  }

  ::v-deep {
    .custom-select__select {
      width: fit-content;
      color: $c-gray-dark;
      font-size: 1.5rem;
      line-height: 1.866;
      background-color: transparent;
    }
  }
}

.job-listing {
  @include mq(l) {
    display: flex;
    flex-wrap: wrap;

    ::v-deep {
      .job-listing__item {
        width: 50%;
      }
    }
  }
}
