
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.flexible-keyfacts {
  position: relative;
  width: col(16);
  transition: opacity 0.2s;

  &.has-image {
    color: $c-white;
  }
}

.flexible-keyfacts-inner {
  display: flex;
  flex-direction: column;

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.flexible-keyfacts__picture {
  /* stylelint-disable-next-line declaration-no-important */
  position: absolute !important;
  z-index: -1;
  top: 0;

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    background-color: rgba($c-black, 0.8);
  }
}

.flexible-keyfacts__content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq($from: l) {
    flex-direction: row;
    justify-content: space-between;
  }

  .has-image & {
    padding: $spacing * 3 0;

    @include mq(m) {
      padding: $spacing * 4 0;
    }

    @include mq(xl) {
      padding: $spacing * 5 0;
    }
  }
}

.flexible-keyfacts__content__title {
  margin-bottom: $spacing * 2;
  color: currentColor;

  @include mq(m) {
    width: col(6);
  }
}

.flexible-keyfacts__content__keyfigures {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ::v-deep {
    .keyfigures-grid {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: unset;
      margin-left: unset;

      @include mq(s) {
        flex-direction: row;
      }

      .keyfigures-grid__item {
        width: 100%;
        margin-top: 0;
        margin-bottom: 3rem;

        @include mq(s) {
          width: col(3.25, 7);
        }
      }
    }

    .flexible-keyfacts__content__btn {
      margin-top: 1rem;

      @include mq(s) {
        margin-top: 5rem;
      }
    }
  }

  @include mq(l) {
    width: col(7, 14);
  }
}

.flexible-keyfacts__content__keyfigure {
  & + & {
    margin-left: $spacing * 2.5;
  }
}
