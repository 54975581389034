
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.job-listing {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.job-listing__item {
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-bottom: 5rem;
  }
}

.job-listing__entry {
  color: $c-black;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  @include mq(l) {
    display: flex;
  }
}

.job-listing__entry__head {
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 2rem;
  opacity: 0.6;

  @include mq(l) {
    width: col(2, 9);
    margin-bottom: 0;
    padding-right: 4rem;
    text-align: right;
  }
}

.job-listing__entry__title {
  padding-bottom: 2rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  border-bottom: 1px solid rgba($c-black, 0.1);
  transition: border-bottom 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid $c-black;
  }

  @include mq(l) {
    width: col(7, 9);
  }
}
