
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































.news-highlight {
  position: relative;
  color: $c-black;

  &.is-dark {
    padding-top: get-spacing(l, s) !important;
    padding-bottom: get-spacing(l, s) !important;
    color: $c-white;
    background-color: $c-black;

    @include mq(m) {
      padding-top: get-spacing(l, m) !important;
      padding-bottom: get-spacing(l, m) !important;
    }

    @include mq(xl) {
      padding-top: get-spacing(l, l) !important;
      padding-bottom: get-spacing(l, l) !important;
    }
  }
}

.news-highlight-inner {
  @include mq(l) {
    display: flex;
  }
}

.news-highlight__content__headline {
  margin-bottom: $spacing;

  @include mq(l) {
    margin-left: col(3, 8);
  }
}

.news-highlight__header__title {
  small {
    @extend %fw-normal;

    font-size: 1em;
  }
}

.news-highlight__header__baseline {
  width: 80%;
  margin-top: $spacing;
  font-size: 1.8rem;
  opacity: 0.7;

  @include mq(m) {
    margin-top: $spacing * 1.5;
  }
}

.news-highlight__head {
  width: 100%;
  margin-bottom: 3rem;

  @include mq(l) {
    width: col(4, 14);
    margin-bottom: 0;
  }
}

.news-highlight__content {
  @extend %fw-normal;

  position: relative;
  width: 100%;
  font-size: 1.5rem;

  ol {
    @extend %list-nostyle;

    li {
      padding: $spacing 0;

      & + li {
        margin-top: $spacing;
      }

      h5 {
        margin: 0;
      }

      p {
        margin-top: $spacing * 0.5;
        opacity: 0.8;
      }
    }
  }

  @include mq(l) {
    width: col(8, 14);
  }

  // @include mq($until: l) {
  //   margin: 0 col(1);
  //   width: col(14);
  //   margin-top: $spacing * 1.5;
  // }

  // @include mq(l) {
  //   width: col(10);
  //   margin-left: col(0);
  // }

  // @include mq(l) {
  //   width: col(5, 14);
  // }
}

.news-highlight__content__link {
  display: inline-block;
  width: 100%;
  margin-top: $spacing * 1.5;
  margin-left: col(5, 14);

  @include mq(l) {
    margin-left: col(3, 8);
  }

  .link {
    @include mq(l) {
      min-width: col(3, 8);
    }
  }
}
