
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































.flexible-picture {
  transition: opacity 0.2s;

  &.before-load {
    opacity: 0;
  }
}
