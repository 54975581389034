
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































.socials {
  text-align: center;
}

.socials__title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 10px, xxl: 30px));

  line-height: 1.428;
}

.socials__link {
  display: inline-block;
  color: inherit;
  text-decoration: none;

  & + & {
    margin-left: 5rem;
  }
}

.socials__svg {
  // prettier-ignore
  @include fluid(width, (xxs: 30px, xxl: 45px));
  // prettier-ignore
  @include fluid(height, (xxs: 30px, xxl: 45px));

  display: block;
}
