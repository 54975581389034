
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















.cta {
  position: relative;
  display: inline-block;
  padding: 1rem 2.5rem 1rem 5.4rem;
  color: #318700;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  border: 2px #31870033 solid;
  border-radius: 6rem;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: #31870033;
  }

  span {
    display: block;

    &:last-child {
      margin-top: 0.5rem;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
}

.cta__icon {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  fill: currentColor;
}
