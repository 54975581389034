
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































































































































































































































.tl__headline {
  margin-bottom: 2rem;
}

.tl__head {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 50px, xxl: 100px));

  @include mq(m) {
    display: flex;
  }
}

.tl__title {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 20px, xxl: 50px));

  width: 100%;

  @include mq(m) {
    width: col(6, 14);
    margin-bottom: 0;
  }
}

.tl__intro.wysiwyg {
  width: 100%;

  @include mq(m) {
    width: col(5, 14);
    margin-right: 0;
    margin-left: auto;
  }
}

.tl__list-container {
  position: relative;
}

.tl__actions-container {
  position: absolute;
  // Neighbour draggable componenent will have a inlined z-index of 1004 (handled by gsap)
  // We want to make sure the controls are higher up in the z layers to avoid pointer events issues.
  z-index: 2000;
  top: 5rem;
  display: flex;
  flex-direction: column;
  margin-left: -10px;

  @include mq(s) {
    flex-direction: row;
  }
}

.tl__action {
  margin: 0;
  padding: 10px;
  background: transparent;
  border-width: 0;
  opacity: 0.5;
  pointer-events: none;

  svg {
    display: block;
    pointer-events: none;
  }

  &:first-of-type {
    margin-bottom: 1rem;

    @include mq(s) {
      margin-bottom: 2.5rem;
      padding-right: 0;
    }
  }

  &:last-of-type {
    @include mq(s) {
      padding-left: 0;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
  }

  @include mq(s) {
    &:not(:last-of-type) {
      margin-right: 1.6rem;
      margin-bottom: 0;
    }
  }
}

.tl__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tl__list__item {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 80px, xxl: 187px));

  position: relative;
  display: flex;
  flex-shrink: 0;
  margin: 0 6.4rem 0 0;
  padding: 0;
  transition: opacity 0.25s linear;

  &:last-of-type {
    margin-right: 0;
  }

  @include mq(m) {
    opacity: var(--opacity, 1);
  }

  // // DEBUG - start
  // &::before,
  // &::after {
  //   content: attr(data-ratio);
  //   position: absolute;
  //   top: 10px;
  //   padding: 5px;
  //   background: whitesmoke;
  // }

  // &::before {
  //   left: 10px;
  // }

  // &::after {
  //   right: 10px;
  // }
  // // DEBUG - end
}

.tl__date__text-container {
  // prettier-ignore
  @include fluid(margin-right, (xxs: 50px, xxl: 115px));

  flex-shrink: 0;
  max-width: 60vw;
  padding-top: 5rem;

  @include mq(s) {
    max-width: 70vw;
  }

  @include mq(m) {
    max-width: 42rem;
  }
}

.tl__date__date {
  color: $c-main-green;
}

.tl__date__text.wysiwyg {
  margin-top: 1.7rem;

  ::v-deep {
    *:last-child {
      margin-bottom: 0;
    }
  }
}

.tl__date__visual {
  max-width: 80vw;
  max-height: 35rem;
  object-fit: cover;

  @include mq(xxs) {
    max-width: 42rem;
  }
}

.tl__dotnav-container {
  --progress: 0;
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 50px, xxl: 80px));
}

.tl__dotnav {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $c-timeline-green;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &::before {
    z-index: -2;
  }

  &::after {
    z-index: -1;
    transform-origin: 0 0;
    transform: translateY(-50%) scaleX(var(--progress));
    background-color: $c-main-green;
    transition: transform 0.5s $ease-in-out-cubic;
  }
}

.tl__dotnav__button {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0;
  background-color: $c-timeline-green;
  border-width: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s $ease-out;
}

.tl__dotnav__item {
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    padding-right: 7rem;
  }

  &.active {
    .tl__dotnav__button {
      background-color: $c-main-green;
    }
  }
}
