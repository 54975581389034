
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































.cta-outer {
  // When used in flex context, parent should automatically apply 'page__section' class to component
  // so we override unwanted padding and pseudo element border
  .page__section + & {
    @include mq(m) {
      margin-top: 0;
    }
  }

  &.page__section {
    padding-top: 0;
    padding-bottom: 0;

    &::before {
      content: none;
    }
  }

  ::v-deep {
    .picture {
      @include fluid(
        min-height,
        (
          xxs: 120px,
          xxl: 480px,
        )
      );
      @include fluid(
        height,
        (
          xxs: 120px,
          xxl: 480px,
        )
      );
    }
  }
}

.cta {
  position: relative;
  padding: $spacing * 2 0;
  color: $c-white;

  @include mq(m) {
    padding: $spacing * 3 0;
  }

  @include mq(xl) {
    padding: $spacing * 4 0;
  }
}

.cta__background {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    $c-equipment-dark 0%,
    $c-equipment-dark 85%,
    $c-processes-mid 85%,
    $c-processes-mid 95%,
    $c-app-light 95%,
    $c-app-light 100%
  );
}

.cta-inner {
  @include mq(l) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.cta__title {
  margin-bottom: 2rem;

  @include mq($until: m) {
    max-width: 30rem;
  }

  @include mq($from: l) {
    margin-bottom: 0;
  }
}

.cta__link {
  &.link {
    margin-bottom: 0;
  }

  @include mq($from: l) {
    flex-shrink: 0;
    width: col(3, 14);
    margin-right: col(2, 14);
    margin-left: col(2, 14);
  }
}

.cta__picture {
  position: sticky !important;
  z-index: -2;
  bottom: 0;
}

.tricky-bg {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 8rem;
  background-color: $c-white;

  @include mq($until: m) {
    display: none;
  }
}
