// Always 14 columns available - 1 column padding on each side

.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width-max;
  padding-right: col(1, 16);
  padding-left: col(1, 16);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(#{$content-width-max} / 16);
    padding-left: calc(#{$content-width-max} / 16);
  }
}
