
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.logos {
  margin-top: 0;
  padding-top: 2rem;
}

.logos__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.logos__item-outer {
  // prettier-ignore
  @include fluid(width,(xxs: 96px,xxl: 192px,));
  // prettier-ignore
  @include fluid(height,(xxs: 60px,xxl: 120px,));

  position: relative;
}

.logos__item {
  @include image-fit(contain);
}
