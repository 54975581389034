html {
  font-size: calc(1em * 0.625);
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h7,
.h8 {
  display: block;
  margin: 0;
  line-height: 1.1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h5,
.h5 {
  font-family: $ff-alt;
}

h6,
.h6 {
  font-family: $ff-default;
}

h1,
.h1 {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 40px, xxl: 80px));

  line-height: 1.1;
}

.baseline {
  @extend %fw-thin;
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, xxl: 40px));

  line-height: 1.2;
}

h2,
.h2 {
  @extend %fw-extra-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 22px, xxl: 30px));

  line-height: 1.25;
}

h3,
.h3 {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xxl: 22px));

  line-height: 1.25;
}

h4,
.h4 {
  @extend %fw-normal;
  // prettier-ignore
  font-size: 1.8rem;
  line-height: 1.4;
}

h5,
.h5 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 20px));
}

/* stylelint-disable-next-line no-duplicate-selectors */
h6,
.h6 {
  font-size: 1.6rem;
}

.lead {
  font-size: 1.15rem;
}

.small {
  font-size: 1.3rem;
}

.tiny {
  font-size: 0.85em;
}

.category {
  // prettier-ignore
  @extend %text-uppercase;
  @extend %fw-extrabold;

  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.2rem;
}

.hero__title {
  // prettier-ignore
  @include fluid(margin-top, (l: 20px, xxl: 40px));

  margin-bottom: 0;
}

.title--long {
  // prettier-ignore
  @include fluid(font-size, (l: 30px, xxl: 48px));

  line-height: 1.25;
}
