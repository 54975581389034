.wysiwyg,
[class*='wysiwyg--'] {
  h1,
  .h1,
  h2,
  .h2 {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 25px));

    &:not(:first-child) {
      // prettier-ignore
      @include fluid(margin-top, (xxs: 30px, m: 50px));
    }
  }

  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 10px, m: 20px));

    &:not(:first-child) {
      // prettier-ignore
      @include fluid(margin-top, (xxs: 30px, m: 50px));
    }
  }

  p {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    margin-top: 2rem;
    margin-bottom: 2rem;

    li {
      &::marker {
        color: $c-main-green;
      }
    }

    ul,
    ol {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ul li {
    position: relative;
    padding-bottom: 1.5rem;
    line-height: 1.4em;
  }

  ol > li {
    position: relative;
    padding-bottom: 1.5rem;
    counter-increment: li;

    &::before {
      content: counter(li) '. ';
      display: inline-flex;
      margin-right: 1rem;
      color: $c-main-green;
    }
  }

  a {
    color: $c-main-green;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-black;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  // Demande client de pouvoir avoir plusieurs images cote à cote #251
  img {
    display: inline-block;
  }

  img.aligncenter {
    margin: auto;
  }

  iframe {
    width: 100% !important;

    @supports (aspect-ratio: 16 / 9) {
      height: unset;
      aspect-ratio: 16 / 9;
    }
  }

  [class*='action--'] {
    margin-top: $spacing !important;
  }

  // The following block tweaks the styling of CtaBlock component when used as shortcode within WYSIWYG.
  // Use small negative inline margin on screen larger than l.
  .flexible-cta {
    padding: 0;

    .flexible-cta__inner {
      max-width: unset;
    }

    @include mq(xl) {
      $offset: 16rem;

      width: calc(100% + #{$offset} * 2);
      margin-inline: -$offset;
    }
  }
}
